import { connect } from 'react-redux';
import { SignerDocumentModel, OrganizerMetadata, OrganizerFormDataViewModel } from '../../core/domain/models/Organizer/Organizer';
import { ApplicationState } from "../../store/index";
import { actionCreators as OrganizerStore } from '../../store/Organizer/OrganizerStore';
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';
import { actionCreators as PreparerMessageStore } from '../../store/Organizer/PreparerMessageStore';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import { OrganizerSignFlow } from "./OrganizerSignFlow";
import { createLoadingSelector } from '../../store/selectors';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        organizerMetadata: state.organizerMetadata,
        uploadedDocuments: (state.organizerDocument as any).uploadedDocuments,
        preparerMessageStore: state.preparerMessage,
        uploadedDocumentStatus: state.uploadedDocumentStore,
        headerInfo: state.headerInfo,
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {         

        submitSignedDocument: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.submitSignedDocument(clientId, organizerForms, successCallback, failureCallback)),

        autoSaveOrganizer: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            organizerDocument: SignerDocumentModel,
            successCallback: (organizerDocument: SignerDocumentModel) => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.autoSaveOrganizer(clientId, organizerForms, organizerDocument, successCallback, failureCallback)),

        finishDocumentSign: (clientId: string,
            organizerForms?: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.finishDocumentSign(clientId, organizerForms, successCallback, failureCallback)),

        requestOrganizerMetadata: (clientId: string) =>
            dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId)),

        addUploadedDocument: (clientId: string,
            uploadedDocument: any,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.addUploadedDocument(clientId, uploadedDocument, successCallback, failureCallback)),

        deleteUploadedDocument: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.deleteUploadedDocument(clientId, id, successCallback, failureCallback)),

        requestUploadedDocuments: (clientId: string,
            forceRefresh?: boolean) => dispatch(OrganizerStore.requestUploadedDocuments(clientId, forceRefresh)),

        completeDocumentUpload: (clientId: string,
            deletedFiles: string,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.completeDocumentUpload(clientId, deletedFiles, successCallback, failureCallback)),
            
        requestOrganizerPreparerMessage: (clientId: string) => dispatch(PreparerMessageStore.requestOrganizerPreparerMessage(clientId)),

        requestUploadedDocumentStatus: (clientId: string) =>
            dispatch(UploadedDocumentStore.requestUploadedDocumentStatus(clientId))

    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrganizerSignFlow);
